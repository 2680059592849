<template>
  <div class="disclosure">
    <h1>Polestar 2 Charging Plan</h1>
    <h2>Promotion Terms and Conditions</h2>
    <p class="primary-color">
      <strong>Effective as of October 1, 2021</strong>
    </p>
    <p>
      Electrify America and Polestar are pleased to provide this promotional charging program to drivers of new Model
      Year 2021 and 2022 Polestar 2 vehicles purchased from an authorized Polestar retailer or via Polestar.com. By
      clicking “Agree” below, you agree to participate in this promotion, subject to your compliance with these terms
      and conditions.
    </p>
    <p>
      Participation requires an Electrify America account, the Electrify America mobile app, enrollment in this Polestar
      2 Charging Plan on the app, and acceptance of the Electrify America Terms of Use governing your use of the app and
      Electrify America’s network. Your mobile carrier’s normal messaging, data, and other rates and fees may apply to
      your use of the Electrify America app.
    </p>
    <p>
      Immediately upon your registration and acceptance of these terms, you will be enrolled in program that provides a
      complimentary 30 minutes of DC fast (CCS) and Level 2 charging at the beginning of charging sessions for your 2021
      or 2022 Polestar 2 on Electrify America’s network of electric vehicle charging stations. This program shall
      continue for a 24-month period starting from the date of vehicle purchase (provided to Electrify America by your
      vehicle’s manufacturer), unless earlier terminated by Electrify America as described below.
    </p>
    <p>
      After signing up, you can redeem your complimentary charging during your initiation of a charging session at any
      Electrify America charging station, exclusively by using the Electrify America app “Swipe to start” or NFC Pass
      feature with the appropriate plan selected. This complimentary charging may not be initiated using the interface
      screen or buttons on the charger.
    </p>
    <p>
      There is no additional fee, purchase, or subscription required for your access to this promotion. After the
      initial 30 minutes of each applicable session, charging continues uninterrupted at then-current Electrify America
      Pass pricing. Subject to the wait period and other restrictions described below, there is no limit on the number
      of eligible charging sessions or the kWh amount of charging available in each session during the program.
    </p>
    <p class="primary-color">
      <strong>PLEASE NOTE THE FOLLOWING IMPORTANT LIMITATIONS ON YOUR USE OF THIS PROMOTION:</strong>
    </p>
    <p>
      You may <strong>only</strong> use this complimentary credit for charging the Polestar 2 whose VIN you submitted
      when signing up for this promotion on your Electrify America account.
      <strong>No other vehicle is eligible to receive this credit through your account,</strong> whether owned by you or
      others.
    </p>
    <p>
      You may <strong>not</strong> use this free charging time in connection with any taxi, ride-share, or other similar
      service, or for any other commercial purpose.
      <strong>This promotion is for your personal, non-commercial use only.</strong>
    </p>
    <p>
      There is a 60-minute wait-period between charging sessions in which you receive the complimentary 30 minutes of
      charging under this promotion. Electrify America reserves the right to apply the then-current Electrify America
      Pass price if you initiate a new charging sessions during this wait-period.
    </p>
    <p>
      Complimentary charging time <strong>does not include</strong> any idle fees and their associated applicable taxes
      assessed at the charging station. You will be responsible for paying such fees through your account if you incur
      them during a charging session.
    </p>
    <p>
      You must follow all product, vehicle, safety, and technical documentation included with the Polestar 2 when
      charging at an Electrify America charging station.
    </p>
    <p>
      You must use the correct connector type and charge at the correct power level for the Polestar 2 at the charging
      station.
    </p>
    <p>
      Once the 24-month term of this promotion has expired, you will not be able to reload, renew, or add more credit to
      this promotional plan. Please refer to the information on the <strong>“Plan Details”</strong> section of the app
      to keep track of the amount of time remaining in this promotion.
    </p>
    <p>
      This credit is not returnable or redeemable for any cash value, and may not be sold, loaned, or otherwise
      distributed to any third party for any reason. You may not transfer any portion of the credit to any other
      Electrify America account.
    </p>
    <p>
      You acknowledge that your use of Electrify America’s services is subject to the Electrify America Terms of Use
      available at
      <router-link class="link" :to="{ name: 'terms-en' }">https://www.electrifyamerica.com/terms</router-link>
      and that your use of the Electrify America mobile app is subject to the Terms of Use accessible by navigating to
      the <strong>Profile</strong> icon in top right hand corner of map, selecting <strong>“Legal”</strong> and then
      selecting <router-link class="medium" :to="{ name: 'terms-en' }">“Terms of Use”</router-link> &amp;
      <router-link class="medium" :to="{ name: 'privacy-en' }">“Privacy Policy.”</router-link> Please read these Terms
      of Use documents carefully for information about how you can pay through your account for idle fees and applicable
      taxes as well as for charging session time outside of the complimentary charging offered under this promotion.
    </p>
    <p>
      You agree to comply with all applicable laws and regulations when using Electrify America services and when
      charging your vehicle at Electrify America charging stations.
    </p>
    <p>
      Electrify America reserves the right to withhold, revoke, reduce, terminate, or suspend your access to all or any
      portion of this promotion, without notice, if Electrify America determines or suspects, in its sole discretion,
      that you: (a) are in violation of the Electrify America Terms of Use, these Promotion Terms and Conditions, or any
      other contract between you and Electrify America; (b) have engaged in charging sessions that are excessive,
      fraudulent, or otherwise indicate an unauthorized or unintended use of this complimentary promotion; or (c) have
      engaged in any illegal, fraudulent, tortious, injurious, harmful, or abusive conduct in your use of the Electrify
      America services, the Electrify America mobile app, or an Electrify America charging station.
    </p>
    <p>
      Electrify America reserves the right to end this complimentary promotion at any time before its automatic
      termination date for any reason, in its sole discretion, provided that Electrify America will send an email to the
      address associated with your account in the event of any change to the scheduled end date of this promotion.
      Following the termination or expiration of this program for any reason, your access to the complimentary promotion
      will immediately cease, and you may not use or redeem any remaining portion of the complimentary charging time. At
      that time, your account will automatically convert to a basic Electrify America “Pass” account, at no additional
      fee or charge to you (neither one-time nor recurring). You may, but are not obligated to, sign up for a different
      type of subscription via the Electrify America mobile app.
    </p>
  </div>
</template>

<script>
export default {
  name: 'polestar2-charging-plan-disclosure',
  metaInfo: {
    title: 'Polestar 2 Charging Plan-disclosure  | Electrify America',
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/polestar2-charging-plan-disclosure/' }],
  },
};
</script>
